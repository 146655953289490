import React from 'react';
import "./style.scss"
import BannerTop from "./images/img-row.png"

const HomePage = () => {
    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="main-header">
                        <div className="logo">
                            <img src="./images/logo.png" alt="" />
                        </div>
                        <div className="main-menu">
                            <ul className="list-menu">
                                <li>
                                    <a href="#about">ABOULT</a>
                                </li>
                                <li>
                                    <a href="#token">TOKENOMICS</a>
                                </li>
                                <li>
                                    <a href="#">CONTACT</a>
                                </li>
                                <li>
                                    <a href="https://t.me/SolanaGarfield" target='blank'>TELEGRAM</a>
                                </li>
                                <li>
                                    <a href="https://x.com/GarfieldxSol" target='blank'>TWITTER</a>
                                </li>
                            </ul>
                        </div>
                        <div className="main-button">
                            <img src="./images/main-button-1.png" alt="" />
                        </div>
                    </div>
                </div>
            </header>
            <section className="section-banner">
                <div className="container">
                    <div className="main-banner">
                        <div className="top-content">
                            <div className="left">
                                <div className="box-img">
                                    <img src="./images/home.gif" alt="" />
                                </div>
                            </div>
                            <div className="right">
                                <div className="wrap-content">
                                    <div className="small-title">
                                        introducing
                                    </div>
                                    <div className="title">
                                        <img src="./images/main-icon.png" alt="" />
                                    </div>
                                    <div className="desc">
                                        coldest lazy cat
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="center-content">
                            <div className="main-desc">
                                Garfield, the iconic character from the classic cartoon, now comes to the world of cryptocurrency
                            </div>
                            <div className="contract-address">
                                <img src="./images/Copy.png" alt="" /> N/A
                            </div>
                            <div className="gr-buy">
                                <ul className="list-dex">
                                    <li>
                                        <a href="#">BUY ON SOLANA</a>
                                    </li>
                                    <li>
                                        <a href="#">Birdeye</a>
                                    </li>
                                    <li>
                                        <a href="#">Dexscreener</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-grafield" id="about">
                <div className="line">
                    <img src="./images/line.png" alt="" />
                </div>
                <div className="container">
                    <div className="main-grafield">

                        <div className="left">
                            <div className="box-img">
                                <img src="./images/s-1.png" alt="" />
                            </div>
                        </div>
                        <div className="right">
                            <div className="content-row">
                                <div className="title">
                                    WHo IS GARFIELD?
                                </div>
                                <div className="desc">
                                    Welcome to Garfield Cat, where we celebrate the lazy, lasagna-loving cat as a meme coin on the Solana blockchain. Garfield, the iconic and beloved character from the classic cartoon, now comes to life in the world of cryptocurrency, bringing his charm and laziness to the digital realm.
                                </div>
                                <div className="main-button">
                                    <div className="item">
                                        <a href="https://t.me/SolanaGarfield" target='blank'>
                                            <img src="./images/v-1.png" alt="" />
                                        </a>
                                    </div>
                                    <div className="item">
                                        <a href="https://x.com/GarfieldxSol" target='blank'>
                                            <img src="./images/v-2.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-buy-work">
                <div className="top-buy">
                    <img src="./images/top-buy.png" alt="" />
                </div>
                <div className="container">
                    <div className="main-buy">
                        <div className="left-buy">
                            <img src="./images/left-buy.png" alt="" />
                        </div>
                        <div className="title">
                            <span>To buy </span><img src="./images/how.png" alt="" /> HOW TO BUY <img src="./images/how.png" alt="" /> <span>HOW TO</span>
                        </div>
                        <div className="item-row">
                            <div className="icon">
                                <img src="./images/n-1.png" alt="" />
                            </div>
                            <div className="desc-row">
                                <div className="txt">
                                    Create a wallet
                                </div>
                                <div className="desc">
                                    Download Phantom or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to phantom app
                                </div>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="icon">
                                <img src="./images/n-2.png" alt="" />
                            </div>
                            <div className="desc-row">
                                <div className="txt">
                                get some sol
                                </div>
                                <div className="desc">
                                Have SOL in your wallet to switch to $GFCAT If you dont have any SOL, you can buy SOL from an exchange or cross chain swap and send it to your wallet.
                                </div>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="icon">
                                <img src="./images/n-3.png" alt="" />
                            </div>
                            <div className="desc-row">
                                <div className="txt">
                                go to raydium
                                </div>
                                <div className="desc">
                                Connect to Raydium. Go raydium.io in google chrome or on the browser inside your Phantom app. Connect your wallet. Paste the $GFCAT token address into Raydium and confirm the swap. When Phantom prompts you for a wallet signature, sign.
                                </div>
                            </div>
                        </div>
                        <div className="item-row">
                            <div className="icon">
                                <img src="./images/n-4.png" alt="" />
                            </div>
                            <div className="desc-row">
                                <div className="txt">
                                Swap
                                </div>
                                <div className="desc">
                                Switch SOL for $GFCAT
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-map" style={{ backgroundImage: `url(${BannerTop})` }} id="token">
                <div className="container">
                    <div className="main-map">
                        <div className="left">
                            <div className="box-img">
                                <img src="./images/trok.png" alt="" />
                            </div>
                        </div>
                        <div className="right">
                            <div className="content-map">
                                <div className="title">
                                    TOKENOMIC
                                </div>
                                <div className="item">
                                    Liquidity 100%
                                </div>
                                <div className="item">
                                    Contract renouned
                                </div>
                                <div className="item">
                                    Liquidity burned
                                </div>
                                <div className="item">
                                    Tax 0%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row-contact">
                        <div className="item">
                            Supply: 1.000.000.000
                        </div>
                        <div className="item">
                            Ticker: GFCAT
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-ultilies">
                <div className="container">
                    <div className="main-ultilies">
                        <div className="title">
                            UTILITIES
                        </div>
                        <div className="round-now">
                            <div className="txt">
                            Strategic Collaborations:
                            </div>
                            <div className="desc">
                            Garfield Cat has established valuable partnerships with top-tier companies in the cryptocurrency industry. These collaborations will drive the project's sustainability and growth, allowing us to utilize our partners' resources and expertise effectively.
                            </div>
                        </div>
                        <div className="round-now">
                            <div className="txt">
                            Growing Ecosystem:
                            </div>
                            <div className="desc">
                            The Solana blockchain is witnessing rapid growth, with a multitude of projects and applications emerging on the platform. This vibrant ecosystem provides an excellent backdrop for Garfield Cat's success, enabling us to tap into the wealth of resources and expertise from other projects. As Solana continues to evolve, Garfield Cat is poised to expand and enhance its offerings.
                            </div>
                        </div>
                        <div className="round-now">
                            <div className="txt">
                            Dedicated Community:
                            </div>
                            <div className="desc">
                            Garfield Cat has received overwhelming support from the crypto community, with numerous influencers and industry leaders expressing their confidence in the project. This strong community support lays a solid foundation for our growth, ensuring a loyal user base to advocate for and promote our memecoin on the Solana blockchain.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-avaiable">
                <div className="container">
                    <div className="main-avaiable">
                        <div className="title">
                            AVailable on
                        </div>
                        <div className="round-item">
                            <div className="item">
                                <img src="./images/m-1.png" alt="" />
                                <span><img src="./images/m-6.png" alt="" /></span>
                            </div>
                            <div className="item">
                                <img src="./images/m-2.png" alt="" />
                                <span><img src="./images/m-6.png" alt="" /></span>
                            </div>
                            <div className="item">
                                <img src="./images/m-3.png" alt="" />
                                <span><img src="./images/m-6.png" alt="" /></span>
                            </div>
                            <div className="item">
                                <img src="./images/m-4.png" alt="" />
                                <span><img src="./images/m-6.png" alt="" /></span>
                            </div>
                            <div className="item">
                                <img src="./images/m-5.png" alt="" />
                                <span><img src="./images/m-6.png" alt="" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="footer">
                <div className="container">
                    <div className="main-footer">
                        <div className="logo">
                            <img src="./images/logo-fr.png" alt="" />
                        </div>
                        <div className="social-name">
                            <div className="item">
                                <a href="https://t.me/SolanaGarfield" target='blank'>
                                    <img src="./images/b-1.png" alt="" />
                                </a>
                            </div>
                            <div className="item">
                                <a href="https://x.com/GarfieldxSol" target='blank'>
                                    <img src="./images/x-s.png" alt="" />
                                </a>
                            </div>
                            <div className="item">
                                <a href="#">
                                    <img src="./images/b-2.png" alt="" />
                                </a>
                            </div>
                            <div className="item">
                                <a href="#">
                                    <img src="./images/or.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="desc">
                            copyright @ 2024 garfieldthecat.com
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default HomePage